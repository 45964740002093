import axiosService from '@/tools/axios-service';
import LearnModule, { LearnModulePagination } from '@/models/learn_module';

class ModuleApi {
  async index({ page = 1, text = '', themeIds = [], sortBy = 'desc-publication', draft = false }) {
    const res = await axiosService.get(`/api/v1/learn/modules${draft ? '/drafts' : ''}`, {
      params: {
        'page[number]': page,
        text,
        theme_ids: themeIds,
        sort_by: sortBy,
        'page[size]': 12,
      },
    });

    return LearnModulePagination.fromJson(res.data);
  }

  async get(id: string) {
    const res = await axiosService.get(`/api/v1/learn/modules/${id}`);

    return LearnModule.fromJson(res.data);
  }

  async update({ id, title, duration, cover_offset_left,cover_offset_top }: any) {
    const res = await axiosService.patch(`/api/v1/learn/modules/${id}`, {
      title,
      duration,
      cover_offset_left,
      cover_offset_top,
    });

    return LearnModule.fromJson(res.data);
  }

  async delete(id: string) {
    const res = await axiosService.delete(`/api/v1/learn/modules/${id}`);

    return LearnModule.fromJson(res.data);
  }

  async duplicate(id: string, title: string) {
    const res = await axiosService.post(`/api/v1/learn/modules/${id}/duplicate_module`);

    await this.update({
      id: res?.data?.learn_module?.id,
      title: title,
    })

    return LearnModule.fromJson(res?.data?.learn_module);
  }

  async paginateByTraining(id: string, trainingId: string) {
    const res = await axiosService.get(`/api/v1/learn/modules/${id}/paginate_by_training`, {
      params: {
        training_id: trainingId,
      },
    });

    return LearnModule.fromJson(res.data);
  }

  async toggleFavorite(module: LearnModule) {
    if (!module) return null;
    const res = await axiosService.post(`api/v1/learn/modules/${module.id}/favorite`);

    // Create a shallow copy
    return module?.copyWith({
      connectedUserFavorited: module.connectedUserFavorited,
    });
  }
}

const instance = new ModuleApi();

export default instance;
