<template>
  <svn-pro-form-block
    :id="question?.id"
    :question-type="question?.type"
    :has-title="question?.text?.length ? true : false"
    :title="`${question?.text} ${
      question?.required_for === interviewKind ||
        question?.required_for === 'all' ? '*' : ''
    }`"
    :selected-value="findInterviewAnswer?.answer"
    :rating-length="question?.options"
    :MCQOptions="MCQOptions"
    :comments="question?.type === InterviewAppQuestionTypes?.open ? findInterviewAnswer?.answer : findInterviewAnswer?.comments"
    :type="getFormBlockType(question?.type)"
    :size="isMdScreen ? ComponentSizes?.default : ComponentSizes?.compact"
    :has-divider="question?.type === InterviewAppQuestionTypes?.chapter"
    :has-description="question?.description?.length ? true : false"
    :description="question?.description"
    :has-variable-content="hasVariableContentComputed"
    :has-comment="hasCommentComputed"
    :has-editable-area="question?.type !== InterviewAppQuestionTypes?.chapter && question?.type !== InterviewAppQuestionTypes?.paragraph && !locked"
    :state="interviewTouched && !findInterviewAnswer.answer &&
      (question?.required_for === interviewKind || question?.required_for === 'all') ?
        'error' : 'default'
    "
    @update-comments="question?.type === InterviewAppQuestionTypes?.open ? updateValue('answer', $event) : updateValue('comments', $event)"
    @update-answer="updateValue('answer', $event)"
    @on-comment-clicked="commentDrawer = !commentDrawer"
  >
    <template v-if="findEmployeeQuestion(question?.id) || findManagerQuestion(question?.id) || (findInterviewAnswer?.answer && locked && interviewKind !== 'manager')" #variable-content>
      <div :id="question?.id" class="w-full flex flex-col items-start gap-6 self-stretch">
        <svn-pro-answer-bubble-interview
          v-if="(interviewKind === 'manager' || interviewKind === 'crossed') && findEmployeeQuestion(question.id)"
          :user="employee"
          :rating-length="`${String(question?.options)}`"
          :question-type="question?.type"
          :bubble-title="`${employee?.firstname} ${employee?.lastname}`"
          :selected-value="findEmployeeQuestion(question?.id)?.answer"
          :comment="question?.type === InterviewAppQuestionTypes?.open ? findEmployeeQuestion(question?.id)?.answer : findEmployeeQuestion(question?.id)?.comments"
        />
  
        <svn-pro-answer-bubble-interview
          v-if="interviewKind === 'manager' && findManagerQuestion(question.id)"
          :side="false"
          :user="interviewer"
          :rating-length="`${String(question?.options)}`"
          :question-type="question?.type"
          :bubble-title="`${interviewer?.firstname} ${interviewer?.lastname}`"
          :selected-value="findManagerQuestion(question?.id)?.answer"
          :comment="question?.type === InterviewAppQuestionTypes?.open ? findManagerQuestion(question?.id)?.answer : findManagerQuestion(question?.id)?.comments"
        />
  
        <svn-pro-answer-bubble-interview
          v-else-if="interviewKind === 'crossed' && findManagerQuestion(question.id)"
          :side="false"
          :user="interviewer"
          :rating-length="`${String(question?.options)}`"
          :question-type="question?.type"
          :bubble-title="`${interviewer?.firstname} ${interviewer?.lastname}`"
          :selected-value="findManagerQuestion(question?.id)?.answer"
          :comment="question?.type === InterviewAppQuestionTypes?.open ? findManagerQuestion(question?.id)?.answer : findManagerQuestion(question?.id)?.comments"
        />
  
        <svn-pro-answer-bubble-interview
          v-else-if="interviewKind === 'crossed' && findEmployeeQuestion(question.id) && findManagerQuestion(question.id)"
          :side="false"
          :user="interviewer"
          :rating-length="`${String(question?.options)}`"
          :question-type="question?.type"
          :bubble-title="`${interviewer?.firstname} ${interviewer?.lastname}`"
          :selected-value="findManagerQuestion(question?.id)?.answer"
          :comment="question?.type === InterviewAppQuestionTypes?.open ? findManagerQuestion(question?.id)?.answer : findManagerQuestion(question?.id)?.comments"
        />

        <svn-pro-answer-bubble-interview
          v-if="findInterviewAnswer?.answer && locked && interviewKind !== 'manager'"
          :user="findInterviewAnswer?.user"
          :is-last-interview-completed-and-locked="interviewKind === 'crossed' && isLastInterviewCompletedAndLocked"
          :bubble-title="interviewKind === 'crossed' ? $t('Cross review') : `${findInterviewAnswer?.user?.firstname} ${findInterviewAnswer?.user?.lastname}`"
          :crossed="interviewKind === 'crossed'"
          :rating-length="`${String(question?.options)}`"
          :question-type="question?.type"
          :selected-value="findInterviewAnswer?.answer"
          :comment="question?.type === InterviewAppQuestionTypes?.open ? findInterviewAnswer?.answer : findInterviewAnswer?.comments"
        />
      </div>
    </template>
  </svn-pro-form-block>

  <!-- Comments drawer -->
  <bkt-survey-comments
    v-model="commentDrawer"
    :answer-interview-id="findInterviewAnswer?.id"
    :comment="findInterviewAnswer"
    :answer="findInterviewAnswer?.question"
    :is-interview="true"
    :comments="findInterviewAnswer?.messages"
    @close="commentDrawer = false"
    @refetch-answers="refetchAnswers"
  />
</template>

<script setup>
import { debounce } from "lodash";
import { storeToRefs } from "pinia";
import { ref, computed }  from "vue";
import { useSnackbar } from "@/store/snackbar";
import { useMobileStore } from "@/store/mobile";
import { useSurveyStore } from "@/store/survey.js";
import { useInterviewStore } from "@/store/interview.js";
import BktSurveyComments from "@/components/surveyApp/BktSurveyComments.vue";
import { FormBlockTypes, ComponentSizes, InterviewAppQuestionTypes } from 'svn-ui-library/components'

const snackbar = useSnackbar();
const { fetchSurveyAnswers } = useSurveyStore();
const { updateInterviewAnswer } = useInterviewStore();

const {
  interviewKind,
  campaign,
  isLastInterviewCompletedAndLocked,
  interviewAnswers,
  locked,
  employee,
  interviewer,
  employeeAnswers,
  managerAnswers,
  interviewTouched
} = storeToRefs(useInterviewStore());
const { answers } = storeToRefs(useSurveyStore());
const { isMdScreen } = storeToRefs(useMobileStore());

const props = defineProps(["question", "loopIdx"]);

const commentDrawer = ref(false);

const MCQOptions = computed(() => {
  if (props?.question?.options?.length) {
    return props?.question?.options?.map(option => option?.value)
  } else {
    return []
  }
});

const hasCommentComputed = computed(() => {
  return (locked?.value && findInterviewAnswer?.value?.comment?.length &&
    findInterviewAnswer?.value?.messages?.length && campaign?.value?.campaign_type === 'Survey'
  )
});

const hasVariableContentComputed = computed(() => {
  if (props?.question?.type !== InterviewAppQuestionTypes?.chapter && props?.question?.type !== InterviewAppQuestionTypes?.paragraph) {
    if (findEmployeeQuestion(props?.question?.id) || findManagerQuestion(props?.question?.id)) {
      return true
    } else if (findInterviewAnswer?.value) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
})

const findInterviewAnswer = computed(() => {
  return (interviewAnswers?.value?.find((answer) => answer?.question_id === props?.question?.id) || {});
});

const updateValue = debounce((key, value) => {
  findInterviewAnswer.value[key] = value;
  updateRemote(key, value);
}, 400)

const updateRemote = (key) => updateInterviewAnswer(props?.loopIdx, props?.question?.id, {
  [key]: findInterviewAnswer?.value?.[key],
});

const findEmployeeQuestion = (questionId) => {
  return employeeAnswers?.value?.find((answer) => answer.question_id === questionId);
};

const findManagerQuestion = (questionId) => {
  return managerAnswers?.value?.find((answer) => answer?.question_id === questionId);
};

const getFormBlockType = (questionType) => {
  if ([InterviewAppQuestionTypes?.chapter, InterviewAppQuestionTypes?.paragraph, InterviewAppQuestionTypes?.open]?.includes(questionType)) {
    return FormBlockTypes?.text_area
  } else if (questionType === InterviewAppQuestionTypes?.rating) {
    return FormBlockTypes?.icon_buttons
  } else if (questionType === InterviewAppQuestionTypes?.mcq) {
    return FormBlockTypes?.radio_buttons
  } else {
    return FormBlockTypes?.text_area
  }
}

const setCrossDefaultAnswer = () => {
  let managerAnswers = findManagerQuestion(props?.question?.id);
  let employeeAnswers = findEmployeeQuestion(props?.question?.id);

  if (!findInterviewAnswer?.value?.answer) {
    findInterviewAnswer.value.answer = managerAnswers
      ? managerAnswers?.answer
      : employeeAnswers
      ? employeeAnswers?.answer
      : null;
    updateRemote("answer", findInterviewAnswer?.value?.answer);
  }

  if (!findInterviewAnswer?.value?.comments) {
    findInterviewAnswer.value.comments = managerAnswers
      ? managerAnswers.comments
      : employeeAnswers
      ? employeeAnswers.comments
      : null;

    updateRemote("comments", findInterviewAnswer?.value?.comments);
  }
};

if (interviewKind?.value === "crossed" && !locked?.value) {
  setCrossDefaultAnswer();
}

const refetchAnswers = async() => {
  try {
    await fetchSurveyAnswers(findInterviewAnswer?.value?.question?.interview_app_form_id, findInterviewAnswer?.value?.question?.id);
    let answer = interviewAnswers?.value?.find((answer) => answer.question_id === props?.question?.id)
    answer.messages = answers?.value?.find((answer) => answer?.question_id === props?.question?.id)?.messages
  } catch {
    snackbar.setBgColor('onSurface').setMsg('An error occured, please try again.').displaySnackBar();
  }
}
</script>